













import { Component, Vue } from 'vue-property-decorator';
import Action from '@/modules/marketing/MarketingAction.vue';
import MarketingFAQ from '@/modules/marketing/MarketingFAQ.vue';
@Component({
  components: {
    MarketingFAQ,
    Action,
  },
  layout: 'default',
  metaInfo: {
    title: 'FAQ',
    // override the parent template and just use the above title only
  },
})
export default class FAQ extends Vue {}
