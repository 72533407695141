
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class MarketingAction extends Vue {
  async signIn() {
    await this.$msal.signIn({ state: 'register' });
  }
}
