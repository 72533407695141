
import { Component, Vue } from 'vue-property-decorator';
import { BadgerAccordion, BadgerAccordionItem } from 'vue-badger-accordion';

@Component({
  components: {
    BadgerAccordion,
    BadgerAccordionItem,
  },
})
export default class MarketingFAQ extends Vue {}
